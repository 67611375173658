
import { defineComponent } from "vue";

export default defineComponent({
  name: "Lactation",
  setup() {

    const openFile = () => {
      console.log("openFile()");
    }

    const getFileDetails = () => {
      const apiUrl = "https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=guia-de-lactancia.pdf";
      fetch(apiUrl, { method: 'head', mode: 'cors' })
      .then(res => {
        console.log(res);
        // this.fileInfo.size = numeral(res.headers.get('content-length')).format('0.00 b');
        // this.fileInfo.type = res.headers.get('content-type');
      })
      .catch(err => {
        console.error(err);
      });
    }

    // getFileDetails();

    return {
      openFile
    }
  }
});
